import gql from 'graphql-tag'

export const CREATE_UPDATE_ORGANIZATION_USER_ADDRESS = gql`
mutation ($orgs:[Organizations_insert_input!]!, $org_on_conflict_rule: Organizations_on_conflict!,
          $users:[Users_insert_input!]!, $users_on_conflict_rule: Users_on_conflict!,$address:[Addresses_insert_input!]!,
          $address_on_conflict_rule: Addresses_on_conflict!) {
    Organization:insert_Organizations(objects: $orgs, on_conflict: $org_on_conflict_rule){
        returning{
            id
            name
            mobile
            zone
            lat
            long
            Type{
                id
                type
            }
        }
    },
    User:insert_Users(objects: $users, on_conflict: $users_on_conflict_rule){
        returning{
            id
        }
    },
    Address:insert_Addresses(objects: $address, on_conflict: $address_on_conflict_rule){
        returning{
            id
        }
    }
}`

export const CREATE_UPDATE_ORGANIZATION_USER_ADDRESS_EXECUTIVE = gql`
mutation ($orgs:[Organizations_insert_input!]!, $orgId:uuid!, $org_on_conflict_rule: Organizations_on_conflict!,
          $users:[Users_insert_input!]!, $users_on_conflict_rule: Users_on_conflict!,$address:[Addresses_insert_input!]!,
          $address_on_conflict_rule: Addresses_on_conflict!, $orgSalesExecutives:[OrganizationSalesExecutive_insert_input!]!) {
    Organization:insert_Organizations(objects: $orgs, on_conflict: $org_on_conflict_rule){
        returning{
            id
            name
            mobile
            zone
            lat
            long
            Type{
                id
                type
            }
        }
    },
    User:insert_Users(objects: $users, on_conflict: $users_on_conflict_rule){
        returning{
            id
        }
    },
    Address:insert_Addresses(objects: $address, on_conflict: $address_on_conflict_rule){
        returning{
            id
        }
    },
    delete_OrganizationSalesExecutive(where: {orgId: {_eq: $orgId}}) {
        returning { id }            
    },
    insert_OrganizationSalesExecutive(objects: $orgSalesExecutives){
        returning { id }           
    }
}`

export const UPDATE_ORGANIZATION_SALES_EXECUTIVE = gql`
    mutation ($id: uuid!, $orgSalesExecutives:[OrganizationSalesExecutive_insert_input!]!){
        delete_OrganizationSalesExecutive(where: {orgId: {_eq: $id}}) {
            returning { id }            
        },
        insert_OrganizationSalesExecutive(objects: $orgSalesExecutives){
            returning { id }           
        }
    }`
export const SOFT_DELETE_ORGANIZATION = gql`
    mutation ($id: uuid!,$status:String!){
        update_Organizations(where: {id: {_eq: $id}}, _set: {status: $status}){
            returning{
                id
            }
        }
    }`
