import gql from 'graphql-tag'

export const SUBSCRIBE_DISTRIBUTORS_COUNT = gql`
    subscription ($orgType:[uuid!]){
        Distributors:Organizations_aggregate(where:{ type:{ _in: $orgType}}){
            aggregate{
                count
            }
        }
    }`

export const SUBSCRIBE_CUSTOMERS_COUNT = gql`
    subscription ($orgType:[uuid!]){
        Customers:Organizations_aggregate(where:{ type:{ _in: $orgType}}){
            aggregate{
                count
            }
        }
    }`

export const SUBSCRIBE_DISTRIBUTORS = gql`
    subscription ($condition:Organizations_bool_exp,$userType:[uuid!]){
        Distributors:Organizations(where:$condition, order_by:{created_at:desc}){
            id
            orgCode
            status
            name
            mobile
            whatsappNumber
            gstInNumber
            zone
            lat
            long
            companyType
            fssaiCode
            fssaiCodeExpiryDate
            totalDueAmount
            orderSystem
            assignedSalesExecutive
            rtoCode
            created_at
            updated_at
            Type{
                id
                type
            }
            SalesExecutives{id salesExecutiveId SalesExecutive{id name mobile}}
            SalesExecutive{
                id
                name
                mobile
            }
            Users(where:{userTypeId:{_in:$userType},isKeyOwner:{_eq:true}}){
                id
                name
                mobile
                email
                birthDate
                anniversary
                userTypeId
                isKeyOwner
                isKeyPerson
            }
            Address{
                id
                address1
                address2
                area
                city
                state
                country
                pincode
            }
            Routes{
                id
                name
                organizationId
                created_at
            }
        }
    }`

export const SUBSCRIBE_DISTRIBUTOR_BY_ID = gql`
    subscription ($id:uuid!,$userType:[uuid!]){
        Organizations(where:{id:{_eq:$id}}){
            id
            orgCode
            status
            name
            mobile
            whatsappNumber
            gstInNumber
            zone
            lat
            long
            companyType
            fssaiCode
            fssaiCodeExpiryDate
            totalDueAmount
            orderSystem
            assignedSalesExecutive
            rtoCode
            created_at
            updated_at
            Type{
                id
                type
            }
            CreatedBy{
                id
                name
            }
            UpdatedBy{
                id
                name
            }
            SalesExecutives{id salesExecutiveId SalesExecutive{id name mobile}}
            SalesExecutive{
                id
                name
                mobile
            }
            Users(where:{userTypeId:{_in:$userType}}){
                id
                name
                mobile
                email
                birthDate
                anniversary
                userTypeId
                isKeyOwner
                isKeyPerson
                Type{
                    id
                    name
                }
            }
            Address{
                id
                address1
                address2
                area
                city
                state
                country
                pincode
            }
            Routes{
                id
                name
                organizationId
                created_at
            }
            PurchaseOrders{
                id
                orderNum
                created_at
                tallyInvoiceNum
                roundOfValue
                invoiceDate
            }
            SalesOrders_aggregate {aggregate {count}}
        }
    }`

export const SUBSCRIBE_CUSTOMERS = gql`
    subscription ($orgType:[uuid!],$userType:uuid!){
        Customers:Organizations(where:{ type:{ _in: $orgType}}, order_by:{created_at:desc}){
            id
            name
            status
            mobile
            whatsappNumber
            gstInNumber
            zone
            lat
            long
            shopType
            fssaiCode
            fssaiCodeExpiryDate
            totalDueAmount
            orderSystem
            created_at
            updated_at
            Type{
                id
                type
            }
            CreatedBy{
                id
                name
            }
            UpdatedBy{
                id
                name
            }
            Users(where:{userTypeId:{_eq:$userType}}){
                id
                name
                mobile
                email
                birthDate
                anniversary
            }
            Address{
                id
                address1
                address2
                area
                city
                state
                country
                pincode
            }
            RouteShops_aggregate {
                aggregate {
                    count
                }
            }
        }
    }`

export const SUBSCRIBE_CUSTOMER_BY_ID = gql`
    subscription ($id:uuid!,$userType:uuid!){
        Organizations(where:{ id:{ _eq: $id}}){
            id
            name
            status
            mobile
            whatsappNumber
            gstInNumber
            zone
            lat
            long
            shopType
            fssaiCode
            fssaiCodeExpiryDate
            totalDueAmount
            orderSystem
            created_at
            updated_at
            Attachments { id image }
            Type{
                id
                type
            }
            CreatedBy{
                id
                name
            }
            UpdatedBy{
                id
                name
            }
            Users(where:{userTypeId:{_eq:$userType}}){
                id
                name
                mobile
                email
                birthDate
                anniversary
            }
            Address{
                id
                address1
                address2
                area
                city
                state
                country
                pincode
            }
            Routes_aggregate {
                aggregate {
                    count
                }
            }
        }
    }`

export const SUBSCRIBE_ORGANIZATION_TYPES = gql`
    subscription {
        OrganizationTypes(where:{type:{_neq:"Distributor"}}){
            id
            type
        }
    }`

export const SUBSCRIBE_ACTIVE_ORGANIZATION_COUNT = gql`
    subscription ($orgType:[uuid!]){
        ActiveOrganizations:Organizations_aggregate(where: {type:{_in:$orgType},status: {_eq: "ACTIVE"}}){
            aggregate{
                count
            }
        }
    }`
export const SUBSCRIBE_INACTIVE_ORGANIZATION_COUNT = gql`
    subscription ($orgType:[uuid!]){
        InActiveOrganizations:Organizations_aggregate(where: {type:{_in:$orgType},status: {_eq: "INACTIVE"}}){
            aggregate{
                count
            }
        }
    }`
