<template>
  <div id="CreateUpdateOrganization">
    <v-dialog persistent scrollable max-width="1200px" v-model="saveOrganizationDialog">
      <v-card>
        <v-card-title class="px-3">{{ org ? 'Update' : 'Create' }} Customer</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <v-form ref="organizationForm">
            <v-row>
              <v-col class="px-6" cols="7">
                <v-row>
                  <v-col cols="12" class="pl-1">
                    <h5 class="text-subtitle-1 font-weight-medium black--text">Company Details</h5>
                  </v-col>
                  <v-col cols="12" class="py-0 pl-1">Do we have FSSAI?</v-col>
                  <v-col class="py-0 pl-1" cols="12">
                    <v-radio-group row v-model="hasFSSAI">
                      <v-radio label="Yes" :value="true"></v-radio>
                      <v-radio label="No" :value="false"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col v-if="hasFSSAI" class="py-0 pl-0" cols="7" :class="hasFSSAI ? 'd-block d-flex' : 'd-none'">
                    <v-text-field class="text-body-2" dense clearable label="FSSAI Number" outlined
                                  ref="fssaiCode" v-model="organization.fssaiCode"></v-text-field>
                    <v-btn depressed @click="getDataFromFSSAI" class="ml-3" color="primary">SEARCH</v-btn>
                  </v-col>
                  <v-col cols="12" class="pa-0">
                    <v-text-field dense :rules="[rules.companyNameRequired]" clearable label="Enter Company Name*"
                                  outlined ref="name" v-model="organization.name"></v-text-field>
                  </v-col>
                  <v-col cols="12" class="pa-0">
                    <v-select dense :rules="[rules.companyTypeRequired]" :items="organizationTypes" item-text="type"
                              item-value="id" clearable outlined label="Select Customer Type*"
                              v-model="organization.type"></v-select>
                  </v-col>
                  <v-col cols="12" class="pa-0">
                    <v-select dense :items="shopTypes" clearable outlined label="Shop Type"
                              v-model="organization.shopType"></v-select>
                  </v-col>
                  <v-col cols="12" class="pa-0 mb-2">
                    <v-text-field dense v-mask="'##NNNNNNNNNNNZX'" clearable label="GSTIN Number" outlined ref="gstin"
                                  v-model="organization.gstInNumber"></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="px-4" cols="5">
                <v-row class="px-2">
                  <v-col cols="12" class="pl-1">
                    <h5 class="text-subtitle-1 font-weight-medium black--text">Address</h5>
                  </v-col>
                  <v-col cols="12" class="pa-0">
                    <v-text-field dense clearable label="Address Line 1" outlined ref="address1"
                                  v-model="organization.Address.address1"></v-text-field>
                  </v-col>
                  <v-col cols="12" class="pa-0">
                    <v-text-field dense clearable label="Address Line 2" outlined ref="address2"
                                  v-model="organization.Address.address2"></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0 px-0">
                    <v-text-field dense clearable label="Area" outlined ref="area"
                                  v-model="organization.Address.area"></v-text-field>
                  </v-col>
                  <v-col cols="6" class="py-0 pl-0">
                    <v-text-field dense clearable label="Pincode" outlined ref="pincode"
                                  v-model="organization.Address.pincode"></v-text-field>
                  </v-col>
                  <v-col cols="6" class="py-0 pr-0">
                    <v-text-field dense clearable label="City" outlined ref="city"
                                  v-model="organization.Address.city"></v-text-field>
                  </v-col>
                  <v-col cols="6" class="py-0 pl-0">
                    <v-text-field dense clearable label="State" outlined ref="state"
                                  v-model="organization.Address.state"></v-text-field>
                  </v-col>
                  <!--                  <v-col cols="6" class="py-0 pl-0">-->
                  <!--                    <v-text-field dense label="Zone" clearable outlined ref="zone"-->
                  <!--                                  v-model="organization.zone"></v-text-field>-->
                  <!--                  </v-col>-->
                  <v-col cols="6" class="py-0 pr-0">
                    <v-text-field dense clearable label="Country" outlined ref="country"
                                  v-model="organization.Address.country"></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="px-3">
                <v-row>
                  <v-col cols="12" >
                    <h5 class="text-subtitle-1 font-weight-medium black--text">Owner Details</h5>
                  </v-col>
                  <v-col cols="3" class="py-0">
                    <v-text-field dense :rules="[rules.nameRequired]" clearable label="Name*"
                                  outlined ref="name" v-model="organization.User.name"></v-text-field>
                  </v-col>
                  <v-col cols="2" class="py-0">
                    <v-text-field dense :rules="[rules.mobileRequired]" clearable label="Mobile*"
                                  outlined ref="name" v-model="organization.User.mobile"></v-text-field>
                  </v-col>
                  <v-col cols="3" class="py-0">
                    <v-text-field :rules="[rules.emailValid]" dense label="Email"
                                  outlined ref="email" v-model="organization.User.email"></v-text-field>
                  </v-col>
                  <v-col cols="2" class="py-0">
                    <v-menu :close-on-content-click="false" :nudge-right="40" min-width="290px" offset-y
                            transition="scale-transition" v-model="birthDateMenu">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field clearable dense label="BirthDate" outlined prepend-inner-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs" v-model="birthDateFormat" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker @input="birthDateMenu = false"
                                     v-model="organization.User.birthDate"></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="2" class="py-0">
                    <v-menu :close-on-content-click="false" :nudge-right="40" min-width="290px" offset-y
                            transition="scale-transition" v-model="anniversaryMenu">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field clearable dense label="Anniversary" outlined prepend-inner-icon="mdi-calendar"
                                      readonly v-bind="attrs" v-model="anniversaryFormat" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker @input="anniversaryMenu = false"
                                     v-model="organization.User.anniversary"></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="cancel" class="mr-2 white elevation-0">CANCEL</v-btn>
          <v-btn depressed :loading="loading" @click="validateDetails" color="primary">SAVE</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { SUBSCRIBE_ORGANIZATION_TYPES } from '@/graphql/subscriptions/Organizations'
import { IDS } from '@/Constants/IDS'
import { CREATE_UPDATE_ORGANIZATION_USER_ADDRESS } from '@/graphql/mutations/Organizations'
import { GET_ORGANIZATION_USER_BY_MOBILE } from '@/graphql/queries/Organizations'
import moment from 'moment'
import NetworkCommunicator from '@/plugins/NetworkResourceHandler'

export default {
  name: 'CreateUpdateOrganization',
  props: {
    org: { type: Object, default: null },
    saveOrganizationDialog: { type: [Boolean], default: false },
  },
  apollo: {
    $subscribe: {
      OrganizationTypes: {
        query: SUBSCRIBE_ORGANIZATION_TYPES, fetchPolicy: 'network-only',
        result ({ data, loading }) {
          if (!loading) { this.organizationTypes = data.OrganizationTypes }
        },
      },
    },
  },
  data () {
    return {
      loggedInUser: JSON.parse(localStorage.getItem('user')),
      birthDateMenu: false, anniversaryMenu: false, loading: false, hasFSSAI: false,
      organization: {
        fssaiCode: null, fssaiCodeExpiryDate: null, mobile: '', whatsappNumber: '', type: '', name: '', zone: '',
        shopType: '', gstInNumber: '', parentOrgId: '', createdBy: '',
        Address: { address1: '', address2: '', area: '', city: '', pincode: '', state: '', country: '' },
        User: { id: null, name: '', email: null, birthDate: null, anniversary: null },
      },
      shopTypes: ['Farsan', 'Bakery', 'Kirana', 'Mini Mall', 'Tobaco', 'Traders', 'Mall', 'Pan and Cold Drinks', 'Other'],
      organizationTypes: [],
      rules: {
        mobileRequired: value => !!value || 'Mobile Required.',
        companyTypeRequired: value => !!value || 'Company Type Required.',
        companyNameRequired: value => !!value || 'Company Name Required.',
        nameRequired: value => !!value || 'Owner Name Required.',
        latRequired: value => !!value || 'Latitude Required.',
        longRequired: value => !!value || 'Longitude Required.',
        emailValid: v => !v ||
            /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                v) || 'E-mail must be valid',
      },
    }
  },
  computed: {
    birthDateFormat: {
      get () {
        return this.organization.User.birthDate
            ? moment(this.organization.User.birthDate, 'YYYY-MM-DD').format('DD-MM-YYYY') : null
      },
      set () {
        this.organization.User.birthDate = null
      },
    },
    anniversaryFormat: {
      get () {
        return this.organization.User.anniversary
            ? moment(this.organization.User.anniversary, 'YYYY-MM-DD').format('DD-MM-YYYY') : null
      },
      set () {
        this.organization.User.anniversary = null
      },

    },
  },
  mounted () {
    if (this.org) {
      this.fillDataFromOrg(this.org)
    }
  },
  methods: {
    async saveOrganization () {
      let org, user, address
      org = {
        name: this.organization.name, type: this.organization.type, shopType: this.organization.shopType,
        zone: this.organization.zone, gstInNumber: this.organization.gstInNumber,
        whatsappNumber: this.organization.User.mobile, mobile: this.organization.User.mobile,
        fssaiCode: this.organization.fssaiCode, status: 'ACTIVE',
        fssaiCodeExpiryDate: this.organization.fssaiCodeExpiryDate,
        createdBy: this.loggedInUser.id, updatedBy: this.loggedInUser.id,
        parentOrgId: this.loggedInUser.organizationId,
      }
      if (org.type === IDS.organizationTypes.Dealer)
        org['priceListId'] = IDS.userPriceList.DealerSurat
      else if (org.type === IDS.organizationTypes.Retailer)
        org['priceListId'] = IDS.userPriceList.RetailerSurat
      user = {
        name: this.organization.User.name, email: this.organization.User.email,
        mobile: this.organization.User.mobile, userTypeId: IDS.userTypes.Customer,
        createdBy: this.loggedInUser.id, updatedBy: this.loggedInUser.id,
      }
      if (this.organization.User.birthDate)
        user['birthDate'] = this.organization.User.birthDate
      if (this.organization.User.anniversary)
        user['anniversary'] = this.organization.User.anniversary
      address = {
        city: this.organization.Address.city, state: this.organization.Address.state,
        area: this.organization.Address.area, pincode: this.organization.Address.pincode,
        country: this.organization.Address.country, address1: this.organization.Address.address1,
        address2: this.organization.Address.address2,
      }
      let orgId = this.generateUUID()
      if (this.org) {
        org['id'] = this.org.id
      } else {
        org['id'] = orgId
      }
      if (this.org && this.org.Users.length) {
        user['id'] = this.organization.User.id
        user['organizationId'] = this.org.id
      } else {
        user['organizationId'] = orgId
      }

      if (this.org && this.org.Address) {
        address['id'] = this.organization.Address.id
        address['organizationId'] = this.org.id
      } else if (this.org) {
        address['organizationId'] = this.org.id
      } else {
        address['organizationId'] = orgId
      }

      let org_on_conflict_rule = {
        constraint: 'Organizations_pkey',
        update_columns: [
          'name', 'type', 'shopType', 'zone', 'gstInNumber', 'mobile', 'whatsappNumber', 'fssaiCode',
          'fssaiCodeExpiryDate', 'address', 'status', 'priceListId', 'updatedBy'],
      }
      let users_on_conflict_rule = {
        constraint: 'Users_pkey',
        update_columns: ['name', 'email', 'mobile', 'userTypeId', 'birthDate', 'anniversary'],
      }
      let address_on_conflict_rule = {
        constraint: 'Addresses_pkey',
        update_columns: [
          'city', 'state', 'area', 'pincode', 'country', 'address1', 'address2', 'latitude', 'longitude'],
      }
      let variables = {
        orgs: org, org_on_conflict_rule: org_on_conflict_rule,
        users: user, users_on_conflict_rule: users_on_conflict_rule,
        address: address, address_on_conflict_rule: address_on_conflict_rule,
      }
      let res = await this.$apollo.mutate({
        mutation: CREATE_UPDATE_ORGANIZATION_USER_ADDRESS, variables: variables,
      })
      if (res.data) {
        this.$emit('saveOrganization', res.data.Organization.returning[0])
        this.$emit('cancel')
      }
    },
    getDataFromFSSAI () {
      NetworkCommunicator('GET', `https://api.rajwafers.anant.io/api/fssaiDetails/${this.organization.fssaiCode}`).
          then(res => {
            if (res.flag) {
              let orgData = res.data.FboDetailsLicenseWise
              this.organization.name = orgData.Company
              this.organization.fssaiCodeExpiryDate = orgData.ExpireDate
              this.organization.User.name = orgData.Contact_Person
              this.organization.User.email = orgData.EmailId
              this.organization.Address.state = orgData.State
              this.organization.Address.city = orgData.District_Name
              this.organization.mobile = orgData.FBO_MobileNo
              this.organization.User.mobile = orgData.FBO_MobileNo
              this.organization.whatsappNumber = orgData.FBO_MobileNo
              this.$forceUpdate()
            }
          })
    },
    fillDataFromOrg (org) {
      if (typeof org === 'object') {
        setTimeout(() => {
          this.organization['id'] = org.id
          this.organization.type = org.Type.id
          this.organization.shopType = org.shopType
          this.organization.mobile = org.mobile
          this.organization.whatsappNumber = org.whatsappNumber
          this.organization.gstInNumber = org.gstInNumber
          this.organization.name = org.name
          this.organization.zone = org.zone
          this.organization.fssaiCode = org.fssaiCode
          this.organization.fssaiCodeExpiryDate = org.fssaiCodeExpiryDate
          if (org.Address && org.Address.id)
            this.organization.Address = org.Address
          if (org.Users.length) {
            this.organization.User.id = org.Users[0].id
            this.organization.User.name = org.Users[0].name
            this.organization.User.mobile = org.Users[0].mobile
            this.organization.User.email = org.Users[0].email
            this.organization.User.birthDate = org.Users[0].birthDate
            this.organization.User.anniversary = org.Users[0].anniversary
          }
        }, 200)
      }
    },
    validateDetails () {
      this.loading = true
      if (this.$refs.organizationForm.validate())
        this.checkMobileDuplication()
      else
        this.loading = false
    },
    async checkMobileDuplication () {
      this.loading = true
      let response = await this.$apollo.query({
        query: GET_ORGANIZATION_USER_BY_MOBILE, fetchPolicy: 'network-only',
        variables: {
          userId: this.org && this.org.Users.length ? this.organization.User.id : IDS.DUMMY_ID,
          organizationId: this.org ? this.org.id : IDS.DUMMY_ID,
          mobile: this.organization.User.mobile,
        },
      })
      if (response.data) {
        if (response.data.Organizations.length || response.data.Users.length) {
          this.loading = false
          this.$emit('showToast', { enable: true, color: 'red', message: 'Mobile Already Exists' })
        } else
          await this.saveOrganization()
      }
    },
    cancel () {
      this.$emit('cancel')
    },
    generateUUID () {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
      })
    },
  },
}
</script>
